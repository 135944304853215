$light-gray2: #ebebeb;
$red: #cd0000;
$light-gray: #f8f8f8;
$blue: #111cf0;
$almost-white: #fefeff;
$white: #fff;
$yellow: #ffc540;
$gray: #acacac;
$gray2: #bababb;
$dark-gray: #505151;
$blue-gray: #313A50;


$body-bg: $white;
$sidebar-bg: $light-gray2;
$app-dark-bg: $light-gray2;
$danger: $red;
$menu-background: $almost-white;
$body-bg: $white;
$header-bg: $light-gray;
$indicator-inactive: $gray;
$menu-toggle-color: $dark-gray;
$module-menu-item-color: $blue-gray;
$static-menu-item-color: $gray2;
$menu-border-color: $gray2;


$title-color: $dark-gray;

/// Always add default color at least
$primary: $blue;
$secondary: $yellow;
$link-color: $primary;
$colors: (
  'primary': (
    'default': $primary,
    'lighter': lighten($primary, 10),
    'darker': darken($primary, 10),
  ),
  'secondary': (
    'default': $secondary,
    'lighter': lighten($secondary, 10),
    'darker': darken($secondary, 10),
  ),
  'danger': (
    'default': $danger
  )
);


:root {
  --color-primary: #{$primary};
  --color-secondary: #{$secondary};
}

// Will add colors to styledComponents while refactoring styles. There seems to be too many here
