$default-line-height: 1.15;

$header-height: 4.5rem;
$footer-height: 2rem;

$nav-button-margins: 0.5rem;
$nav-button-height: 2.75rem;

$breakpoints:(
  'xs': 0, /// Extra small screen / phone
  'sm': 576px, /// Small screen / phone
  'md': 768px, /// Medium screen / tablet
  'lg': 992px, /// Large screen / desktop
  'xl': 1200px /// Extra large screen / desktop
);

// refactored styledComponents vars
