/// PS! We're moving towards styled-components
/// In the future there will only be external vendor imports here.


/// import abstracts
@import
  'abstracts/colors',
  'abstracts/sizes',
  'abstracts/variables',
  'abstracts/zindex',
  'abstracts/animations',
  'abstracts/functions',
  'abstracts/mixins';

/// import vendors
@import 'vendors/normalize';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~animate.css';

/// import base
@import 'base/base',
  /// 'base/fonts',
  'base/helpers';

/// import layouts
@import 'layouts/header',
  'layouts/page';

/// import components
@import
  'components/button',
  'components/module',
  'components/modules/comment',
  'components/modules/vote',
  'components/modal';

/// import r_containers - prefixing with r_ for now, because they are React Containers
/// Will probably be removed, when these styles are moved to components/pages/layouts directories
@import 'r_containers/app.scss';

/// import pages
@import 'pages/welcome',
  'pages/terms-welcome',
  'pages/results';
