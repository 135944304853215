.vote-results {
  background-color: $white;
  display: flex;
  flex-direction: column;
  &__title {
    font-size: 1rem;
  }
  &__subtitle {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0 0 0.5rem;
  }
  .banner {
    position: relative;
    &.no-image {
      .module-header {
        position: relative;
      }
    }
    &__image {
      display: block;
      padding-top: 50%;
      position: relative;
      width: 100%;
      background-size: cover;
      background-position: center;
      transition: padding-top 0.25s ease-in-out;
      @include breakpoint('lg') {
        padding-top: 37.25%;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.2);
      }
    }
    .module-header {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-left: 16px;
      padding-right: 16px;
      text-align: center;
      color: white;
      background: linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6741071428571428) 63%, rgba(0,0,0,0) 100%);
      &__title {
        font-size: 1rem;
      }
      &__subtitle {
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0 0 0.5rem;
      }
    }
  }
  &__header {
    text-align: center;
  }
  &__icon {
    font-size: 4rem;
    margin-top: 1rem;
  }
  &__footer {
    padding-bottom: $footer-height;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &__cta {
    background-color: var(--color-primary);
    color: var(--color-text-on-primary);
    width: 100%;
    display: block;
    padding: 1rem 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  &__content {
    list-style: none;
    padding: 0 1rem;
  }
}

.vote-result {
  &__title {
    font-size: 1rem;
    font-weight: bold;
  }
  &__progress-bar {
    height: 5px;
    background-color: lightgrey;
    width: 100%;
    position: relative;
    margin: 0.5rem auto;
    border-radius: 2px;
    &-result {
      position: absolute;
      height: 5px;
      border-radius: 2px;
      left: 0;
      background-color: var(--color-primary);
      transition: width 0.5s ease-in-out;
      &:after {
        content: '';
        border-radius: 15px/15px;
        border: solid 5px var(--color-primary);
        width: 15px;
        height: 15px;
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $white;
      }
    }
  }
}
