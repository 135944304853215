#root {
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  position: relative;
}
.app {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: black;
  overflow: hidden;
  transition: background-color 0.15s ease-in-out;
  &.dark {
    background-color: $app-dark-bg;
  }
}

.app-content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  background: $sidebar-bg;
  width: 100%;
}

.main-content {
  flex-grow: 1;
  background-color: $body-bg;
  padding: 1rem 2rem 1rem 1rem;
  margin: 10px 0;
  border-radius: 5px;
}
