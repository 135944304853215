.module__comment {
  .header {
    &__title,
    &__subtitle {
      color: #000;
    }
  }

  .comment-not-allowed__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .comment-not-allowed__text {
      display: none;
      width: 70%;
      text-align: center;
    }
  }

  .add-comment {
    margin-top: 1.5rem;

    &.comment-reply-form {
      margin-top: 0.5rem;
    }
  }

  .field {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    input, textarea {
      width: 100%;
      display: block;
      padding: 0.25rem 1rem;
      font-size: 0.75rem;
      border: none;

      &:focus {
        outline: 0;
      }
    }

    &__name {
      &.has-error {
        border-color: $danger;

        .label__text {
          color: $danger;
        }
      }

      .field__char-count {
        position: absolute;
        right: 0;
        top: 0.2rem;
      }

      position: relative;
      border-bottom: 1px solid $menu-toggle-color;

      .comment-author {
        background-color: $app-dark-bg;
      }

      .label {
        &__text {
          position: absolute;
          display: block;
          left: 0;
          pointer-events: none;
          transition: all 0.15s ease-in-out;
          bottom: 0;
          padding-left: 1rem;
          font-weight: bold;
          font-size: 0.75rem;
          margin-bottom: 0.25rem;

          &.field-active {
            bottom: 100%;
            padding-left: 0;
          }
        }
      }
    }

    &__comment {
      &.has-error {
        .comment-area::placeholder {
          color: $danger;
        }
      }

      .comment-area {
        transition: height 0.1s ease-in-out;
        padding: 0.75rem 4.5rem 0.75rem 1rem;
        resize: none;
        border-radius: 0.2rem;
      }

      &-container {
        position: relative;
      }
    }

    &__submit {
      position: absolute;
      right: 0.425rem;
      bottom: 0.425rem;

      button {
        font-size: 0.75rem;
        padding: 0.35rem;
        border: none;
        border-radius: 0.2rem;
        color: $white;
        background-color: $primary;

        &.disabled {
          background-color: lightgrey;
          color: $menu-toggle-color;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    &__char-count {
      font-size: 0.75rem;
    }
  }

  .comment {
    width: 100%;
    background-color: $white;
    border-radius: 0.5rem 0.5rem 0.5rem 0;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;

    &__header,
    &__footer {
      display: flex;
    }

    &__header {
      margin-bottom: 0.5rem;
      flex-direction: row;
      align-items: center;
    }

    &__footer {
      margin-top: 0.5rem;

      & > :last-child {
        margin-left: auto;
      }
    }

    &__author {
      font-weight: bold;

      &.moderated {
        color: var(--color-primary);
      }
    }

    &__moderated {
      margin-left: 0.2rem;
      width: 1rem;
      height: 1rem;

      img {
        width: 100%;
      }
    }

    &__time {
      margin-left: 0.5rem;
      color: $menu-border-color;

      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 5px;
        content: "\f111";
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.5rem;
      }
    }
  }

  .comment-reply {
    width: 90%;
    margin-left: auto;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: $white;
      top: 0;
      bottom: 0.75rem;
      left: -0.75rem;
    }

    .comment__footer > :last-child {
      margin-left: 0;
    }
  }
}

.has-reply-action {
  .module__comment {
    .scrollbar-container {
      height: 100%;

      &-content {
        height: 100%;
      }

      .thread {
        //height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .comment-reply-form-container {
          margin-top: auto;
          margin-bottom: 0;
        }

        &.d-none {
          display: none;
        }
      }
    }
  }

  .close-reply {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    color: $title-color;
    font-size: 1.5rem;
    margin-bottom: -0.25rem;
    @include animate('swoosh-translateY', 20px, 0.25s);

    &__description {
      font-size: 0.75rem;
      margin-right: 0.5rem;

      & .description {
        margin-right: 0.25rem;
      }

      & .author {
        font-weight: 500;
      }
    }
  }
}
