/// A mixin to add animation
/// Available animations can be seen in the _animations.scss file
///
/// @example: animate a div to shake
/// div {
///   @include animate('shake');
/// }
@mixin animate($animation, $perspective: 1000px, $duration: 0.82s) {
  animation: unquote($animation) $duration cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: $perspective;
}

@mixin breakpoint($breakpoint, $only: false) {
  @if($only) {
    @media (min-width: get_breakpoint($breakpoint)) and (max-width: map-get-next($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @media (min-width: get_breakpoint($breakpoint)) {
      @content;
    }
  }
}

// Refactored to styledComponents
