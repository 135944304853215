.page.terms.from-welcome {
  background-color: var(--color-secondary);
  color: var(--color-text-on-secondary);
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
  .page-inner {
    @include animate('swoosh-bot-pos', 0, 0.25s);
    padding: 2rem 2.5rem 3.5rem;
    top: 0;
    left: 0;
    right: 0;
  }
  .home a{
    color: var(--color-primary);
  }
}