.page.welcome {
  height: 100%;
  position: relative;
  background-color: var(--color-secondary);
  color: var(--color-primary);
  display: flex;
  flex: 1;
  padding: 0;
  & .welcome-background {
    height: 45%;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, rgba(var(--color-secondary-rgb-string), 1) 0%, rgba(var(--color-secondary-rgb-string), 0.7) 50%, rgba(var(--color-secondary-rgb-string), 0) 100%);
      content: '';
    }
  }
  & .page-inner {
    @include animate('swoosh-bot-pos', 0, 0.25s);
    padding: 2rem 2.5rem 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 65%;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    color: var(--color-primary);
    text-align: center;
    .page__title h1 {
      font-size: 2.5rem;
      text-transform: uppercase;
      font-weight: 500;
    }
    .page__subtitle h3 {
      font-weight: normal;
      font-size: 1.25rem;
    }
    .page__conditions {
      position: relative;
      font-size: 0.75rem;
      margin-left: -10px;
      margin-right: -10px;
      button {
        color: var(--color-text-on-secondary);
        font-size: 2rem;
        transform: translateY(0.3rem);
        background-color: transparent;
        border: none;
        padding: 0.5rem;
        &:focus {
          outline: 0;
        }
      }
      .animate-check {
        position: absolute;
        left: 12px;
        font-size: 1.2rem;
        top: 15px;
        pointer-events: none;
        opacity: 1;
        @include animate('animate-scale-out', 100px, 0.25s);
      }
    }
    button, a {
      color: var(--color-text-on-secondary);
    }
    .page__cta {
      width: 100%;
      margin-top: 0.5rem;
      button {
        background-color: var(--color-primary);
        color: var(--color-text-on-primary);
        border: none;
        border-radius: 3px;
        padding: 1rem 2rem;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        transition: opacity 0.25s ease-in-out;
        opacity: 1;
        &[disabled] {
          opacity: 0.5;
        }
      }
    }

  }
}