.module {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .header {
    text-align: center;
    color: white;
    &__title {
      display: inline-block;
      padding-bottom: 0.25rem;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0;
      color: white;
    }
    &__subtitle {
      font-size: 0.75rem;
      margin-top: 0.25rem;
      color: white;
      font-weight: normal;
    }
  }
  &:not(&__comment) {
    .scrollbar-container {
      background-color: white;
    }
  }
  .ps__rail-y {
    z-index: getZ('scrollbar');
  }
}