.module__vote {
  width: 100%;
  .banner {
    position: relative;
    &.no-image {
      .module-header {
        position: relative;
      }
    }
    &__image {
      display: block;
      padding-top: 50%;
      position: relative;
      width: 100%;
      background-size: cover;
      background-position: center;
      transition: padding-top 0.25s ease-in-out;
      @include breakpoint('sm') {
        padding-top: 37.25%;
      }
      @include breakpoint('md') {
        padding-top: 37.25%;
      }
      @include breakpoint('lg') {
        padding-top: 37.25%;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.2);
      }
    }
    .module-header {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-left: 16px;
      padding-right: 16px;
      text-align: center;
      color: white;
      background: linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6741071428571428) 63%, rgba(0,0,0,0) 100%);
      &__title {
        font-size: 1rem;
      }
      &__subtitle {
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0 0 0.5rem;
      }
    }
  }
  .option {
    display: flex;
    background-color: $app-dark-bg;
    margin-bottom: 0.5rem;
    padding: 1rem;
    border-radius: 0.25rem;
    position: relative;
    transition: transform 0.25s ease-in-out;
    &-active {
      transform: translateY(-3px);
      @include breakpoint('sm') {
        transform: translateY(-3px);
      }
    }
    &:focus {
      outline: 0;
    }
    &__image {
      > img {
        display: block;
      }
    }
    &__data {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.5rem;
    }
    .title, .subtitle {
      margin: 0;
      color: $title-color;
      font-weight: 500;
    }
    &__title {
      position: relative;
      padding-bottom: 0.35rem;
      margin-bottom: 0.25rem;
      font-size: 1rem;
      font-weight: 500;
      &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 5rem;
        background-color: black;
        bottom: 0;
      }
    }
    &__subtitle {
      font-size: 0.75rem;
    }
    &__vote-options {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      background-color: rgba(var(--color-primary-rgb-string), 0.5);
      border-radius: 0.25rem;
      .vote-option {
        color: var(--color-text-on-primary);
        display: flex;
        align-items: center;
        flex: 1;
        flex-direction: column;
        font-size: 0.65rem;
        background-color: var(--color-primary);
        height: 100%;
        justify-content: center;
        margin-left: 0.5rem;
        width: 4rem;
        .divider {
          width: 100%;
          height: 1px;
          background-color: $dark-gray;
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;
        }
        .option-count {
          text-transform: uppercase;
          &.counter {
            font-size: 1.25rem;
            font-weight: bold;
            margin-bottom: 0.1rem;
          }
        }
        .option-price {
          &:after {
            content: '€';
            margin-left: 0.1rem;
          }
        }
        &__container {
          margin-right: 0;
          margin-left: auto;
          display: flex;
          align-items: center;
        }
      }
      &.inactive {
        display: flex;
        align-items: center;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: rgba(var(--color-primary-rgb-string), 0.8);
        .vote-option__container {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          background-color: rgba(var(--color-primary-rgb-string), 0.9);
        }
        .message {
          text-align: center;
          font-weight: bold;
          padding: 0.25rem 0.75rem;
          border-radius: 5px;
          margin: 0;
          color: var(--color-text-on-primary);
          &.sub {
            font-weight: normal;
          }
        }
      }
    }
  }
}

@keyframes animate-icon {
  from { background-position:    0; }
  to { background-position: -4000px; }
}
