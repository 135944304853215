/// Base styles

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


body {
  font-family: $font-stack-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

a {
  color: var(--color-primary);
}

.animated {
  animation-duration: 0.25s;
  &.long {
    animation-duration: 1s;
  }
  &.medium {
    animation-duration: 0.5s;
  }
  &.delay-025 {
    animation-delay: 0.25s;
  }
  &.delay-05 {
    animation-delay: 0.5s
  }
}
