.ReactModal {
  &__Overlay {
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    z-index: getZ('modal');
    flex-direction: row;
    justify-content: center;
    padding: 0.625rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__Content {
    display: flex;
    margin: auto;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 32rem;
    max-width: 100%;
    padding: 1.25rem;
    border: none;
    border-radius: 0.3125rem;
    background-color: $body-bg;
    font-family: inherit;
    font-size: 1rem;
    outline: none;
    .modal {
      text-align: center;
      &-inner.loading {
        position: relative;
        .modal-spinner {
          position: absolute;
          background-color: rgba(255, 255, 255, 0.5);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
      &__content {
        font-size: 0.75rem;
        color: $dark-gray;
        h2 {
          font-size: 1rem;
        }
      }
      &__title { }
      &__icon {
        font-size: 4rem;
        color: $dark-gray;
        animation-delay: 0.25s;
        &-success {
          width: 80px;
          height: 80px;
          display: block;
          animation: animate-icon 2s steps(50) infinite;
          transform: scale(1);
          margin: 0 auto;
        }
      }
      .phone-input-container {
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        label {
          font-weight: bold;
          font-size: 1rem;
        }
        input {
          text-align: left;
          font-size: 1rem;
          margin-left: 1rem;
          margin-bottom: 0;
        }
      }
      .btn-link {
        font-size: inherit;
        padding: 0;
        transform: none;
      }
      .page.terms {
        text-align: left;
        color: inherit;
        background-color: inherit;
        padding: 0;
        ol {
          padding-left: 1.5rem;
        }
      }
      .contacts_container {
        .field {
          padding: 0.3rem;
        }
        .field input:not([type='checkbox']) {
          width: 100%;
        }
        #subscribe_to_newsletter {
          margin-right: 0.4rem;
        }
      }
      &__terms {
        margin-top: 0.8rem;
        color: $dark-gray;
        font-size: 12px;
        .btn-link {
          font-size: 12px;
          cursor: pointer;
        }
      }
      &__input {
        height: 2.625rem;
        padding: 0.75rem;
        box-shadow: none;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid $dark-gray;
        font-weight: bold;
        text-align: center;
        max-width: 100% !important;
        width: 100%;
        -moz-appearance: textfield;
        transition: border-color .3s,box-shadow .3s;
        margin: 0 auto 1rem;
        flex-grow: 1;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &:focus {
          border: none;
          outline: none;
          border-bottom: 2px solid var(--color-primary);
          box-shadow: none;
        }
      }
      &__actions {
        margin: 1.25em auto 0;
        display: flex;
        > * {
          width: 100%;
          display: block;
          padding: 1rem 0.5rem;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.75rem;
          border-radius: 0.25rem;
          margin-left: 12px;
          margin-right: 12px;
          cursor: pointer;
        }
        .actions__results {
          border: 2px solid var(--color-primary);
        }
        .actions__confirm {
          background-color: var(--color-primary);
          color: var(--color-text-on-primary);
          min-width: 100px;

          &.comment {
            width: 5rem;
          }
        }
        .actions__secondary {
          background-color: $gray;
          color: $white;
          min-width: 100px;
        }
      }
    }
    .page__conditions {
      position: relative;
      font-size: 0.75rem;
      margin-left: -10px;
      margin-right: -10px;
      width: auto;
      display: inline-block;
      button {
        font-size: 2rem;
        transform: translateY(0.3rem);
        background-color: transparent;
        border: none;
        padding: 0.5rem;
        &:focus {
          outline: 0;
        }
      }
      .animate-check {
        position: absolute;
        left: 12px;
        font-size: 1.2rem;
        top: 15px;
        pointer-events: none;
        opacity: 1;
        @include animate('animate-scale-out', 100px, 0.25s);
      }
    }
  }
}
