.d-block {
  display: block;
}

.full-width {
  width: 100%;
}

.fw-bold {
  font-weight: 500;
}

.hidden {
  visibility: hidden;
}
.d-none {
  display: none;
}

.text-center {
  text-align: center;
}
