@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes swoosh-bot-pos {
  0% {
    bottom: 100%;
    opacity: 0.25;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes swoosh-top-pos {
  0% {
    top: 100%;
    opacity: 0.25;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes swoosh-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes swoosh-left-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes animate-scale-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

@keyframes swoosh-translateY {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes thread-in {
  0% {
    opacity: 0;
    transform: scaleY(0) translateY(-100%);
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }
}

@keyframes thread-out {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  90% {
    opacity: 0;
    transform: scaleY(0);
  }
}

// @TODO: Need to refactor these to styled-components

@keyframes arClap {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -6780px;
  }
}

@keyframes arHeart {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -17017px;
  }
}

@keyframes arSad {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24192px;
  }
}

@keyframes arMad {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -8030px;
  }
}

@keyframes arClapSand {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -2560px;
  }
}

@keyframes arClapYellow {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -3565px;
  }
}

@keyframes arHeartSand {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -11543px;
  }
}

@keyframes arHeartYellow {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -15600px;
  }
}

@keyframes arDancingSand {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -9760px;
  }
}

@keyframes arDancingYellow {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -10000px;
  }
}

@keyframes arSleepySand {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -6720px;
  }
}

@keyframes arSleepyYellow {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -7128px;
  }
}

@keyframes arDanfossAgreed {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -11250px;
  }
}

@keyframes arDanfossClapping {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -9600px;
  }
}

@keyframes arDanfossParty {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -13350px;
  }
}

@keyframes arKoneCare {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24000px;
  }
}

@keyframes arKoneCustomer {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24000px;
  }
}

@keyframes arKoneCourage {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24000px;
  }
}

@keyframes arKoneCollaboration {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24000px;
  }
}

@keyframes arCannesClapping {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -12480px;
  }
}

@keyframes arCannesConfetti {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -14784px;
  }
}

@keyframes arCannesFlame {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -12480px;
  }
}

@keyframes arCannesHeart {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -14784px;
  }
}

@keyframes arCannesJuan {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -18000px;
  }
}

@keyframes arCannesLions {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -21504px;
  }
}

@keyframes arCannesPerfect {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -21312px;
  }
}

@keyframes arCannesTina {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -18000px;
  }
}

@keyframes glowBox {
  from {
    box-shadow: 0 0 10px -5px white;
  }
  to {
    box-shadow: 0 0 10px 5px white;
  }
}
@keyframes glowText {
  from {
    text-shadow: 0 0 2px rgba(255,255,255,0.5);
  }
  to {
    text-shadow: 0 0 10px rgba(255,255,255,0.5);
  }
}

@keyframes arEasClap {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24000px;
  }
}
@keyframes arEasHeart {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24000px;
  }
}
@keyframes arEasRand {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24000px;
  }
}
@keyframes arEasPall {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -24000px;
  }
}
@keyframes arAscentialClap {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -12610px;
  }
}
@keyframes arAscentialHeart {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -14938px;
  }
}
@keyframes arAscentialPerfect {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -21534px;
  }
}
@keyframes arAscentialFlames {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -12610px;
  }
}

@keyframes arAscentialConfetti {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -14938px;
  }
}

@keyframes arCocaCola-Cola {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -29900px;
  }
}

@keyframes arCocaCola-Sprite {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -23750px;
  }
}

@keyframes arCocaCola-Fanta {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -9500px;
  }
}

@keyframes arCocaCola-Water {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -22500px;
  }
}

@keyframes arHeart_green {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -12800px;
  }
}


@keyframes arHeart_yellow {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -12800px;
  }
}
